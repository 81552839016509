<template>
  <data-table-paging-filtering
    :headers="headers"
    :get-items-func="getItems"
    :item-row-background-func="itemRowBackgroundFunc"
    :show-expand="false"
    :dense="false"
    :sync="false"
    uniqueID="members-list"
  >

    <template v-slot:item.status="{ item }">
      <v-chip>
        {{ getStatus(item.status) }}
      </v-chip>
    </template>

  </data-table-paging-filtering>
</template>

<script>
import { APIService } from "@/http/APIService.js";
import DataTablePagingFiltering from "@/components/DataTablePagingFiltering.vue";

const apiService = new APIService();

export default {
  components: {
    "data-table-paging-filtering": DataTablePagingFiltering,
  },

  data: () => ({
    loading: false,
    items: [],

    headers: [
      {
        text: "VS",
        value: "user.id",
      },
      { text: "Poradové číslo člena", value: "member" },
      { text: "Priezvisko", value: "last_name", model: "memberpublic__last_name" },
      { text: "Meno", value: "first_name", model: "memberpublic__first_name" },
      { text: "Mesto/Obec", value: "address_town", model: "memberpublic__address_town" },
      { text: "Okres", value: "address_okres", model: "memberpublic__address_okres" },
      { text: "Status", value: "status" }
    ],
  }),

  mounted: function () { },

  methods: {
    getStatus(status) {
      switch (status) {
        case 'WAITING': return "Čakajúci"
        case 'ACTIVE': return "Aktívny"
        case 'PAUSED': return "Pozastavený"
        case 'CANCELED': return "Zrušený"
        default: return "Žiadny"
      }
    },

    itemRowBackgroundFunc() {
      return "";
    },

    getItems(sort, search, limit, offset, filters) {
      return apiService.getPublicMembers(sort, search, limit, offset, filters);
    },
  },
};
</script>